var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        style: [
          { backgroundColor: _vm.data.style.bgColor },
          { paddingTop: _vm.data.style.paddingTop + "px" },
          { paddingBottom: _vm.data.style.paddingBottom + "px" },
          { paddingLeft: _vm.data.style.paddingAround + "px" },
          { paddingRight: _vm.data.style.paddingAround + "px" },
        ],
      },
      [
        _c(
          "div",
          {
            style: [
              { borderRadius: _vm.data.style.borderRadius + "px" },
              { backgroundColor: _vm.data.style.cardColor },
            ],
          },
          [
            _vm.data.style.display && _vm.data.style.styleType == 1
              ? _c(
                  "div",
                  { staticClass: "x-fc", staticStyle: { height: "155px" } },
                  [
                    _vm.data.style.isWeChat
                      ? _c("div", { staticClass: "item1" }, [
                          _c("div", { staticClass: "img" }, [
                            _c("img", {
                              staticStyle: {
                                height: "100%",
                                "object-fit": "cover",
                                width: "100%",
                              },
                              attrs: {
                                src: _vm.data.style.weChatUrl || _vm.defaultUrl,
                                alt: "社群码",
                              },
                            }),
                          ]),
                          _vm.data.style.described
                            ? _c(
                                "div",
                                {
                                  staticClass: "desc",
                                  style: {
                                    color: _vm.data.style.describedColor,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.data.style.described) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.data.style.isWeChat && _vm.data.style.isOfficialAccounts
                      ? _c("div", { staticClass: "line" })
                      : _vm._e(),
                    _vm.data.style.isOfficialAccounts
                      ? _c("div", { staticClass: "item1" }, [
                          _c("div", { staticClass: "img" }, [
                            _c("img", {
                              staticStyle: {
                                height: "100%",
                                "object-fit": "cover",
                                width: "100%",
                              },
                              attrs: {
                                src:
                                  _vm.data.style.officialAccountsUrl ||
                                  _vm.defaultUrl,
                                alt: "社群码",
                              },
                            }),
                          ]),
                          _vm.data.style.describedOfficialAccounts
                            ? _c(
                                "div",
                                {
                                  staticClass: "desc",
                                  style: {
                                    color: _vm.data.style.describedColor,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.data.style.describedOfficialAccounts
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm.data.style.display && _vm.data.style.styleType == 2
              ? _c("div", { staticClass: "styleTwo x-bc" }, [
                  _c("div", [
                    _vm.data.style.title
                      ? _c(
                          "div",
                          {
                            staticClass: "title",
                            style: { color: _vm.data.style.titleColor },
                          },
                          [_vm._v(_vm._s(_vm.data.style.title) + " ")]
                        )
                      : _vm._e(),
                    _vm.data.style.described
                      ? _c(
                          "div",
                          {
                            staticClass: "desc2",
                            style: { color: _vm.data.style.describedColor },
                          },
                          [_vm._v(" " + _vm._s(_vm.data.style.described))]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "img" }, [
                    _c("img", {
                      staticStyle: {
                        height: "100%",
                        "object-fit": "cover",
                        width: "100%",
                      },
                      attrs: {
                        src: _vm.data.style.weChatUrl || _vm.defaultUrl,
                        alt: "社群码",
                      },
                    }),
                  ]),
                ])
              : !_vm.data.style.display
              ? _c("div", { staticClass: "weChat" }, [
                  _c("div", { staticClass: "left flex1" }, [
                    _c("span", {
                      staticClass: "logo",
                      style: {
                        backgroundImage:
                          "url(" +
                          (_vm.data.style.entranceType == 2
                            ? _vm.data.style.entranceUrl
                            : "https://img01.yzcdn.cn/upload_files/2023/07/14/FhRlvzI_2wr2xP5DV7QQ79iemeM1.png!small.webp") +
                          ")",
                        backgroundColor:
                          "" +
                          (_vm.data.style.entranceType == 2 &&
                          !_vm.data.style.entranceUrl
                            ? "#dadada"
                            : "transparent"),
                      },
                    }),
                    _c("div", { staticClass: "logoTitle" }, [
                      _vm.data.style.title
                        ? _c(
                            "p",
                            {
                              staticClass: "title",
                              style: { color: _vm.data.style.titleColor },
                            },
                            [_vm._v(_vm._s(_vm.data.style.title))]
                          )
                        : _vm._e(),
                      _vm.data.style.described
                        ? _c(
                            "p",
                            {
                              staticClass: "desc",
                              style: { color: _vm.data.style.describedColor },
                            },
                            [_vm._v(" " + _vm._s(_vm.data.style.described))]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "right" }, [
                    _c(
                      "span",
                      {
                        staticClass: "btn",
                        style: { backgroundColor: _vm.data.style.btnBgColor },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            "" +
                              (_vm.data.style.weChatOrOfficialAccountsType == 1
                                ? _vm.data.style.addBtnName
                                : "关注")
                          )
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }