<template>
  <!-- 中间展示商品样式组件 -->
  <div class="coupons">
    <div class="couponList" :style="[
            { marginRight: data.style.marR + 'px' },
            { marginLeft: data.style.marL + 'px' },
            { marginBottom: data.style.marB + 'px' },
            { marginTop: data.style.marT + 'px' },
            { borderRadius: data.style.borRadius + 'px' },
            {background: data.style.type == 2 ? 'linear-gradient(90deg, rgba(255, 113, 15, 0.2) 0%, rgba(255, 66, 93, 0.2) 100%), #fff' : 'transparent'},
            {backdropFilter: data.style.type == 2 ? 'blur(6px);' : 'blur(0);'}
        ]" v-if="data.style.curListStyleIndex == 0">
      <div class="package-top" v-if="data.style.type == 2">
        <div class="title x-x">
          <img class="tip"
            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-03-27/88159639d51f4993b12c1998603ca5e2.png"
            alt="券包" />
          <span style="line-height: 25px;">{{data.style.title}}</span>
        </div>
        <div class="package-price x-x">
          <div class="left">
            <div class="line-hide">{{data.style.mainTitle}}</div>
            <div class="price x-x">
              <div>
                <span style="font-size: 18px;letter-spacing: -2px">￥</span>
                {{salePrice || ''}}
              </div>
              <!-- <div class="origin-price-line">￥199</div> -->
            </div>
          </div>
          <div class="right">
            <div class="btn">立即购买</div>
          </div>
        </div>
        <img alt="优惠券" class="glass" :draggable="false"
          src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-03-27/8f33bb59bd454989995bb10ab5395496.png">
      </div>
      <div :class="{activeIndexTop: data.style.type == 2}"
         style="overflow-x: auto;">
        <CouponItem :type='data.style.type' :isGradation='true' style="width: 100%"
          v-for="(item, index) in list.length > 0 ? list : data.style.type != 3 ? data.temporaryCouponList : data.style.type == 3 ? data.temporaryCardList : []"
          :key="index" class="marB10" :data="item" :styleObj="data.style"></CouponItem>
      </div>
    </div>
    <!--横向滚动-->
    <div class="couponPackage-box" :style="[
            { marginRight: data.style.marR + 'px' },
            { marginLeft: data.style.marL + 'px' },
            { marginBottom: data.style.marB + 'px' },
            { marginTop: data.style.marT + 'px' },
            { borderRadius: data.style.borRadius + 'px' }
        ]" v-if="data.style.curListStyleIndex == 3">
      <div :class="{activeIndexBottom: data.style.type == 2}" class="coupon-list x-f" style="width: 100%;">
        <CouponItem :type='data.style.type' :isGradation='true' :style="[`min-width: calc(100% - 30px) / 3`]"
          v-for="(item, index) in list.length > 0 ? list : data.style.type != 3 ? data.temporaryCouponList : data.style.type == 3 ? data.temporaryCardList : []"
          :key="index" :class="{marR10:data.style.curCardStyleIndex!=4}" :data="item" :styleObj="data.style">
        </CouponItem>
      </div>
      <div class="package-bottom" v-if="data.style.type == 2">
        <div class="left">
          <div class="line-hide">{{data.style.mainTitle}}</div>
          <div class="price x-x">
            <div>
              <span style="font-size: 18px;letter-spacing: -2px">￥</span>
              {{salePrice || ''}}
            </div>
            <!-- <div class="origin-price-line">￥199</div> -->
          </div>
        </div>
        <div class="right">
          <div class="btn">立即购买</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CouponItem
  from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/components/couponItem.vue'

export default {
  name: 'payCoupon',
  components: { CouponItem },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  computed: {
    list() {
      return this?.data?.style?.type != 3 ? this?.data?.couponList : this.data?.timesCardList || []
    },
    salePrice() {
      return this?.data?.style?.type == 1 ? this?.data?.couponList?.[0]?.salePrice : this?.data?.style?.type == 2 ? this?.data?.couponPackageList?.[0]?.packagePrice : this?.data?.timesCardList?.[0]?.salePrice
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.couponList {
  border-radius: 12px;
  background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(255, 113, 15, 0.2)),
      to(rgba(255, 66, 93, 0.2))
    ),
    #fff;
  background: linear-gradient(
      90deg,
      rgba(255, 113, 15, 0.2) 0%,
      rgba(255, 66, 93, 0.2) 100%
    ),
    #fff;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  overflow: hidden;
  .package-top {
    padding: 12px 12px 0;
    position: relative;
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(rgba(255, 113, 15, 0.2)),
        to(rgba(255, 66, 93, 0.2))
      ),
      #fff;
    background: linear-gradient(
        90deg,
        rgba(255, 113, 15, 0.2) 0%,
        rgba(255, 66, 93, 0.2) 100%
      ),
      #fff;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    .title {
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: #ff1f2c;
      margin-bottom: 12px;
      .tip {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
    .package-price {
      border-radius: 10px;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#ff710f),
        to(#ff425d)
      );
      background: linear-gradient(90deg, #ff710f 0%, #ff425d 100%);
      margin-bottom: 9px;
      padding: 16px 12px 24px;
      color: #fff;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      .left {
        .line-hide {
          max-width: 206px;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 4px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .price {
          font-size: 22px;
          line-height: 30px;
          font-weight: 600;
          -webkit-box-align: baseline;
          -ms-flex-align: baseline;
          align-items: baseline;
          .origin-price-line {
            font-size: 12px;
            text-decoration: line-through;
            font-weight: 400;
            margin-left: 4px;
            line-height: 22px;
          }
        }
      }
      .right {
        font-size: 12px;
        text-align: right;
        .btn {
          font-weight: 600;
          margin-top: 8px;
          width: 68px;
          height: 30px;
          color: #ff4c4e;
          background: #fff;
          border-radius: 90px;
          text-align: center;
          line-height: 30px;
        }
      }
    }
    .glass {
      width: 100%;
      position: absolute;
      bottom: -10px;
      left: 0px;
      right: 0px;
      height: 40px;
    }
  }
}
.couponPackage-box {
  overflow: hidden;
  .coupon-list {
    overflow-x: auto;
    position: relative;
  }
  .package-bottom {
    color: #ff3c29;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #fff;
    padding: 14px 12px;
    .left {
      .line-hide {
        max-width: 206px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
        color: #212121;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .price {
        font-size: 22px;
        line-height: 30px;
        font-weight: 600;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
        .origin-price-line {
          font-size: 12px;
          text-decoration: line-through;
          font-weight: 400;
          margin-left: 4px;
          line-height: 22px;
        }
      }
    }
    .right {
      font-size: 12px;
      text-align: right;
      .sale {
        color: #969696;
      }
      .btn {
        font-weight: 600;
        margin-top: 8px;
        width: 68px;
        height: 30px;
        background: #ff3c29;
        color: #fff;
        border-radius: 90px;
        text-align: center;
        line-height: 30px;
      }
    }
  }
}
.activeIndexBottom {
  padding: 12px;
  background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(255, 113, 15, 0.2)),
      to(rgba(255, 66, 93, 0.2))
    ),
    #fff;
  background: linear-gradient(
      90deg,
      rgba(255, 113, 15, 0.2) 0%,
      rgba(255, 66, 93, 0.2) 100%
    ),
    #fff;
}
.activeIndexTop {
  background-image: url("https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-03-27/9f45585ccdbc49a09d0b14eb0fbdb1b0.png");
  padding: 0 12px 12px;
}
::-webkit-scrollbar-track {
  background-color: #fbfcff;
}
::-webkit-scrollbar-thumb {
  width: 6px;
  background: #d9e0ee;
  border-radius: 4px;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
</style>
