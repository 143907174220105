var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.styleObj.curListStyleIndex > 0
      ? _c(
          "div",
          {
            staticClass: "item y-c padd10",
            class: _vm.cardList[_vm.styleObj.curCardStyleIndex].style,
            style: [
              { color: _vm.styleObj.textColor },
              {
                background:
                  "" +
                  (_vm.isGradation
                    ? "linear-gradient(90deg, " +
                      _vm.styleObj.couponColor1 +
                      " 0%, " +
                      _vm.styleObj.couponColor2 +
                      " 100%)"
                    : _vm.styleObj.couponColor),
              },
              {
                borderRadius: _vm.styleObj.curCardStyleIndex == 3 ? "5px" : "",
              },
              { width: _vm.styleObj.curCardStyleIndex == 4 ? "126px" : "100%" },
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "y-c introduce",
                staticStyle: { width: "100%" },
                style: _vm.style2,
              },
              [
                _vm.styleObj.curCardStyleIndex == 4
                  ? _c("div", {
                      staticClass: "before",
                      style: { background: _vm.styleObj.couponColor },
                    })
                  : _vm._e(),
                _vm.styleObj.curCardStyleIndex == 4
                  ? _c("div", {
                      staticClass: "after",
                      style: { background: _vm.styleObj.couponColor },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "omit1",
                    style: {
                      color:
                        _vm.styleObj.curCardStyleIndex == 4
                          ? _vm.styleObj.couponColor
                          : "#ffffff",
                      fontSize:
                        _vm.styleObj.curCardStyleIndex == 4 ? "28px" : "25px",
                      fontWeight:
                        _vm.styleObj.curCardStyleIndex == 4 ? "600" : "normal",
                    },
                  },
                  [
                    _vm.data.couponCaseType == 0 && _vm.type != 3
                      ? _c("span", { staticStyle: { "font-size": "20px" } }, [
                          _vm._v("￥"),
                        ])
                      : _vm._e(),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.type == 3
                            ? _vm.data.totalCount + "次"
                            : _vm.parValue(_vm.data)
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "x-c received",
                    class: { omit: _vm.styleObj.curListStyleIndex > 0 },
                    staticStyle: {
                      width: "100%",
                      "box-sizing": "border-box",
                      "white-space": "pre-wrap",
                    },
                    style: {
                      color:
                        _vm.styleObj.curCardStyleIndex == 4
                          ? "#565656"
                          : "#ffffff",
                      fontSize: "12px",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.type == 3
                            ? _vm.data.goodsName || _vm.data.promoCaseBillName
                            : _vm.couponDesc(_vm.data)
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            ),
            _vm.styleObj.curCardStyleIndex == 4
              ? _c("div", { staticClass: "immediateClaim" }, [
                  _vm._v(" " + _vm._s(_vm.leftName) + " "),
                ])
              : _vm._e(),
          ]
        )
      : _c(
          "div",
          {
            staticClass: "x-bc item marB10",
            class: [
              _vm.cardList[_vm.styleObj.curCardStyleIndex].style,
              { border: _vm.style.curCardStyleIndex == 4 },
            ],
            style: [
              { color: _vm.styleObj.textColor },
              {
                background:
                  "" +
                  (_vm.isGradation
                    ? "linear-gradient(90deg, " +
                      _vm.styleObj.couponColor1 +
                      " 0%, " +
                      _vm.styleObj.couponColor2 +
                      " 100%)"
                    : _vm.styleObj.couponColor),
              },
              {
                border:
                  _vm.styleObj.curListStyleIndex == 3
                    ? "5px solid " + _vm.styleObj.couponColor
                    : "",
              },
              { border: _vm.styleObj.curCardStyleIndex == 4 ? "5px" : "" },
              {
                borderRadius: _vm.styleObj.curCardStyleIndex == 3 ? "5px" : "",
              },
              { width: "100%" },
              { height: _vm.styleObj.curCardStyleIndex == 4 ? "84px" : "" },
              {
                paddingBottom:
                  _vm.styleObj.curCardStyleIndex == 4 ? "5px" : "10px",
              },
            ],
          },
          [
            _vm.styleObj.curCardStyleIndex == 4
              ? _c(
                  "div",
                  { staticClass: "x-f aRow", staticStyle: { width: "80%" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "omit1 leftPrice x-fc",
                        staticStyle: { width: "104px" },
                        style: {
                          color: _vm.styleObj.couponColor,
                          fontSize: "28px",
                          fontWeight: "600",
                        },
                      },
                      [
                        _vm.data.couponCaseType == 0 && _vm.type != 3
                          ? _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("￥")]
                            )
                          : _vm._e(),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.type == 3
                                ? _vm.data.totalCount + "次"
                                : _vm.parValue(_vm.data)
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c("div", {
                      staticClass: "lines",
                      style: { background: _vm.styleObj.couponColor },
                    }),
                    _c("div", {
                      staticClass: "before",
                      style: {
                        background:
                          "" +
                          (_vm.isGradation
                            ? "linear-gradient(90deg, " +
                              _vm.styleObj.couponColor1 +
                              " 0%, " +
                              _vm.styleObj.couponColor2 +
                              " 100%)"
                            : _vm.styleObj.couponColor),
                      },
                    }),
                    _c("div", {
                      staticClass: "after",
                      style: {
                        background:
                          "" +
                          (_vm.isGradation
                            ? "linear-gradient(90deg, " +
                              _vm.styleObj.couponColor1 +
                              " 0%, " +
                              _vm.styleObj.couponColor2 +
                              " 100%)"
                            : _vm.styleObj.couponColor),
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "y-start marL10",
                        style: { color: "#565656", fontSize: "12px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#212121",
                              "margin-bottom": "2px",
                              "font-size": "14px",
                              "line-height": "20px",
                              "font-weight": "600",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.type == 3
                                    ? _vm.data.timesCardName
                                    : _vm.data.couponCaseName
                                )
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "x-c",
                            class: { omit: _vm.styleObj.curListStyleIndex > 0 },
                            staticStyle: {
                              width: "100%",
                              "box-sizing": "border-box",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.type == 3
                                    ? _vm.data.goodsName ||
                                        _vm.data.promoCaseBillName
                                    : _vm.couponDesc(_vm.data)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._m(0),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "x-f introduce",
                    staticStyle: { width: "70%" },
                  },
                  [
                    _vm.styleObj.curCardStyleIndex == 4
                      ? _c("div", {
                          staticClass: "before",
                          style: { background: _vm.styleObj.couponColor },
                        })
                      : _vm._e(),
                    _vm.styleObj.curCardStyleIndex == 4
                      ? _c("div", {
                          staticClass: "after",
                          style: { background: _vm.styleObj.couponColor },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "omit1",
                        staticStyle: { width: "80px" },
                        style: {
                          color:
                            _vm.styleObj.curCardStyleIndex == 4
                              ? _vm.styleObj.couponColor
                              : "#ffffff",
                          fontSize:
                            _vm.styleObj.curCardStyleIndex == 4
                              ? "28px"
                              : "25px",
                          fontWeight:
                            _vm.styleObj.curCardStyleIndex == 4
                              ? "600"
                              : "normal",
                        },
                      },
                      [
                        _vm.data.couponCaseType == 0
                          ? _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("￥")]
                            )
                          : _vm._e(),
                        _c("span", [_vm._v(_vm._s(_vm.parValue(_vm.data)))]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "y-start marL10",
                        style: {
                          color:
                            _vm.styleObj.curCardStyleIndex == 4
                              ? "#565656"
                              : "#ffffff",
                          fontSize: "12px",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(_vm._s(_vm.data.couponCaseName)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "x-c",
                            class: { omit: _vm.styleObj.curListStyleIndex > 0 },
                            staticStyle: {
                              width: "100%",
                              "box-sizing": "border-box",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.couponDesc(_vm.data)) + " ")]
                        ),
                      ]
                    ),
                  ]
                ),
            _c("div", { staticStyle: { width: "20%" } }, [
              _vm.styleObj.curCardStyleIndex == 4
                ? _c(
                    "div",
                    { staticClass: "x-fc", staticStyle: { width: "100%" } },
                    [
                      _c("div", { staticClass: "receive" }, [
                        _vm._v(" " + _vm._s(_vm.leftName) + " "),
                      ]),
                    ]
                  )
                : _c("div", { staticClass: "x-c" }, [
                    _vm._v(" " + _vm._s(_vm.leftName) + " "),
                  ]),
            ]),
          ]
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "overhide" }, [
      _c("div", { staticClass: "shadow" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }