export const COMPONENT_NAMES = {
  mofang: '魔方',
  header: '顶部导航',
  wenben: '标题文本',
  fengefuzhu: '分割辅助',
  gonggao: '公告',
  sousuo: '商品搜索',
  imageNav: '图文导航',
  goods: '商品',
  videoTmp: '视频',
  zhangfen: '涨粉',
  zichan: '会员资产',
  coupon: '优惠券',
  pintuan: '拼团',
  miaosha: '秒杀',
  zhekou: '限时折扣',
  gexinghua: '个性化推荐',
  shangpintu: '商品图',
  shangpinxinxi: '商品信息',
  shangpinguige: '商品规格',
  shangpinpingjia: '商品评价',
  shangpinxiangqing: '商品详情',
  dibudaohang: '底部导航',
  fuwufangshi: '服务方式',
  shangpindaohang: '商品导航',
  biaotilan: '标题栏',
  huiyuanxinxi: '会员信息',
  plusvip: 'PLUS会员',
  liebiaodaohang: '列表导航',
  tubiaozu: '图标组',
  anniuzu: '按钮组',
  kanjia: '砍价',
  immersive: '沉浸式轮播图',
  guanggao: '广告图片',
  tanchuang: '悬浮',
  Kefu: '客服',
  switching: '门店切换',
  navigation: '底部导航栏',
  pageData: '页面设置',
  seniorCube: '高级魔方',
  payCoupon: '付费优惠券',
  hotZone: '热区',
};