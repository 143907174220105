var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "coupons" }, [
    _vm.data.style.curListStyleIndex == 0
      ? _c(
          "div",
          {
            staticClass: "couponList",
            style: [
              { marginRight: _vm.data.style.marR + "px" },
              { marginLeft: _vm.data.style.marL + "px" },
              { marginBottom: _vm.data.style.marB + "px" },
              { marginTop: _vm.data.style.marT + "px" },
              { borderRadius: _vm.data.style.borRadius + "px" },
              {
                background:
                  _vm.data.style.type == 2
                    ? "linear-gradient(90deg, rgba(255, 113, 15, 0.2) 0%, rgba(255, 66, 93, 0.2) 100%), #fff"
                    : "transparent",
              },
              {
                backdropFilter:
                  _vm.data.style.type == 2 ? "blur(6px);" : "blur(0);",
              },
            ],
          },
          [
            _vm.data.style.type == 2
              ? _c("div", { staticClass: "package-top" }, [
                  _c("div", { staticClass: "title x-x" }, [
                    _c("img", {
                      staticClass: "tip",
                      attrs: {
                        src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-03-27/88159639d51f4993b12c1998603ca5e2.png",
                        alt: "券包",
                      },
                    }),
                    _c("span", { staticStyle: { "line-height": "25px" } }, [
                      _vm._v(_vm._s(_vm.data.style.title)),
                    ]),
                  ]),
                  _c("div", { staticClass: "package-price x-x" }, [
                    _c("div", { staticClass: "left" }, [
                      _c("div", { staticClass: "line-hide" }, [
                        _vm._v(_vm._s(_vm.data.style.mainTitle)),
                      ]),
                      _c("div", { staticClass: "price x-x" }, [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "18px",
                                "letter-spacing": "-2px",
                              },
                            },
                            [_vm._v("￥")]
                          ),
                          _vm._v(" " + _vm._s(_vm.salePrice || "") + " "),
                        ]),
                      ]),
                    ]),
                    _vm._m(0),
                  ]),
                  _c("img", {
                    staticClass: "glass",
                    attrs: {
                      alt: "优惠券",
                      draggable: false,
                      src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-03-27/8f33bb59bd454989995bb10ab5395496.png",
                    },
                  }),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                class: { activeIndexTop: _vm.data.style.type == 2 },
                staticStyle: { "overflow-x": "auto" },
              },
              _vm._l(
                _vm.list.length > 0
                  ? _vm.list
                  : _vm.data.style.type != 3
                  ? _vm.data.temporaryCouponList
                  : _vm.data.style.type == 3
                  ? _vm.data.temporaryCardList
                  : [],
                function (item, index) {
                  return _c("CouponItem", {
                    key: index,
                    staticClass: "marB10",
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: _vm.data.style.type,
                      isGradation: true,
                      data: item,
                      styleObj: _vm.data.style,
                    },
                  })
                }
              ),
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.data.style.curListStyleIndex == 3
      ? _c(
          "div",
          {
            staticClass: "couponPackage-box",
            style: [
              { marginRight: _vm.data.style.marR + "px" },
              { marginLeft: _vm.data.style.marL + "px" },
              { marginBottom: _vm.data.style.marB + "px" },
              { marginTop: _vm.data.style.marT + "px" },
              { borderRadius: _vm.data.style.borRadius + "px" },
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "coupon-list x-f",
                class: { activeIndexBottom: _vm.data.style.type == 2 },
                staticStyle: { width: "100%" },
              },
              _vm._l(
                _vm.list.length > 0
                  ? _vm.list
                  : _vm.data.style.type != 3
                  ? _vm.data.temporaryCouponList
                  : _vm.data.style.type == 3
                  ? _vm.data.temporaryCardList
                  : [],
                function (item, index) {
                  return _c("CouponItem", {
                    key: index,
                    class: { marR10: _vm.data.style.curCardStyleIndex != 4 },
                    style: ["min-width: calc(100% - 30px) / 3"],
                    attrs: {
                      type: _vm.data.style.type,
                      isGradation: true,
                      data: item,
                      styleObj: _vm.data.style,
                    },
                  })
                }
              ),
              1
            ),
            _vm.data.style.type == 2
              ? _c("div", { staticClass: "package-bottom" }, [
                  _c("div", { staticClass: "left" }, [
                    _c("div", { staticClass: "line-hide" }, [
                      _vm._v(_vm._s(_vm.data.style.mainTitle)),
                    ]),
                    _c("div", { staticClass: "price x-x" }, [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "18px",
                              "letter-spacing": "-2px",
                            },
                          },
                          [_vm._v("￥")]
                        ),
                        _vm._v(" " + _vm._s(_vm.salePrice || "") + " "),
                      ]),
                    ]),
                  ]),
                  _vm._m(1),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "btn" }, [_vm._v("立即购买")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "btn" }, [_vm._v("立即购买")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }