<template>
  <div>
    <div class="item" v-if="data.style.templateNo == 1" :style="[
            { paddingLeft: data.style.pagePadding + 'px' },
            { paddingRight: data.style.pagePadding + 'px' },
            { backgroundColor: data.style.cardStyle == 2 ? data.style.bgColor : '' },
            { backgroundColor: data.style.cardStyle == 3 ? data.style.bgColor : '' },
            { margin: data.marT + 'px ' + data.marR + 'px ' + data.marB + 'px ' + data.marL + 'px'}
        ]">
      <div :style="[
                { backgroundColor: data.style.cardStyle == 4 ? 'rgba(255,255,255,0)' : data.style.cardBgColor },
                { borderRadius: data.borRadius + 'px' },
                { boxShadow: data.style.cardStyle == 2 ? '-3px 3px 3px rgba(0,0,0,0.2), 3px 0px 3px rgba(0,0,0,0.2)' : '' },
                { border: data.style.cardStyle == 3 ? '1px solid #e9e9e9' : '' },
                { backgroundImage: data.style.bgStyle === 'image' ? `url(${data.style.bgImage})` : '', },
                { backgroundSize: 'cover' }
            ]" class="x-bc" style="padding: 10px">
        <div class="x-f">
          <el-avatar v-if="data.style.isAvatar"
            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/4e24133991c845368af8c104ac945003.jpg"
            :size="40"></el-avatar>
          <div class="y-start marL10">
            <span style="margin-bottom:5px;font-size:14px;" class="fontBold"
              :style="{ color: data.style.textColor }">Hi，亲爱的会员</span>
            <div class="x-f" style="color: #bababa;font-size:11px">
              {{ data.style.propertyMsg }}
              <i class="el-icon-arrow-right" style="margin-left: 2px"></i>
            </div>
          </div>
        </div>
        <div class="x-f">
          <div class="fontS12 y-c" style="margin-right:10px" :style="{ color: data.style.textColor }">
            <span style="font-size:12px;margin-bottom:5px">{{ data.style.descriptionText }}</span>
            <span style="color: #bababa">{{ data.style.descriptionText == '余额'? '￥0' : '0张' }}</span>
          </div>
          <div class="y-f fontS12" v-if="data.style.isVipQrCode">
            <iconpark-icon name="two-dimensional-code-two" size="20px"></iconpark-icon>
            <span style="font-size:9px;color: #bababa">会员码</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="data.style.templateNo == 2" :style="[
            { paddingLeft: data.marL + 'px' },
            { paddingRight: data.marR + 'px' },
            { paddingTop: data.marT + 'px' },
            { paddingBottom: data.marB + 'px' }
        ]">
      <div class="warpCard" :style="[
            { paddingTop: '24px' },
            { borderRadius: data.borRadius + 'px'},
            { backgroundColor: data.style.cardBgColor },
        ]">
        <div>
          <div class="type-two-wrap">
            <div class="top">
              <div class="x-b userinfo-mode-two-type-one">
                <div class="left" :style="{color: data.style.textColor }">
                  <div class="hello">Hello</div>
                  <div class="username">亲爱的会员</div>
                </div>
                <div class="right">
                  <img
                    src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/4e24133991c845368af8c104ac945003.jpg"
                    alt="头像图片" class="avatar">
                  <div class="member-code">
                    <iconpark-icon name="two-dimensional-code-two" size="14px"
                      style="height: 14px;margin-right: 2px;width: 14px"></iconpark-icon>
                    <span>会员码</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom x-fsa">
              <div class="column" v-for="(item, index) in columnList" :key="index">
                <span class="num fontBold" :style="{ color: data.style.realTextColor }">{{item.num}}</span>
                <span class="title" :style="{color: data.style.textColor }">{{item.title}}</span>
              </div>
            </div>
          </div>
          <div v-if="data.style.isVipQrCode">
            <ZiChanCom :layout='data.style.imgLayoutType' :selectedList="data.style.selectedImgList" />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="data.style.templateNo == 3" :style="[
            { paddingLeft: data.marL + 'px' },
            { paddingRight: data.marR + 'px' },
            { paddingTop: data.marT + 'px' },
            { paddingBottom: data.marB + 'px' }
        ]">
      <div class="warpCard" :style="[
            { paddingTop: '24px' },
            { borderRadius: data.borRadius + 'px'},
            { backgroundColor: data.style.cardBgColor },
        ]">
        <div>
          <div class="type-two-wrap">
            <div class="x-b">
              <div class="top2">
                <div class="faces"></div>
                <div class="userName" :style="{color: data.style.textColor }">
                  <div class="head"><span class="name">亲爱的会员</span><img style="height: 16px;width: 20px;"
                      src="https://b.yzcdn.cn/public_files/a429ec4c0459fed1a41693ef8b75b750.png" alt="星鑽"></div>
                  <div class="level">会员等级</div>
                </div>
              </div>
              <div class="y-f">
                <iconpark-icon name="two-dimensional-code-two" size="20px"></iconpark-icon>
                <span style="font-size:12px;color: #bababa">会员码</span>
              </div>
            </div>
            <div class="bottom x-fsa">
              <div class="column" v-for="(item, index) in columnList" :key="index">
                <span class="num fontBold" :style="{ color: data.style.realTextColor }">{{item.num}}</span>
                <span class="title" :style="{color: data.style.textColor }">{{item.title}}</span>
              </div>
            </div>
          </div>
          <div v-if="data.style.isVipQrCode">
            <ZiChanCom :layout='data.style.imgLayoutType' :selectedList="data.style.selectedImgList" />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="data.style.templateNo == 4" :style="[
            { paddingLeft: data.marL + 'px' },
            { paddingRight: data.marR + 'px' },
            { paddingTop: data.marT + 'px' },
            { paddingBottom: data.marB + 'px' }
        ]">
      <div class="warpCard" :style="[
            { paddingTop: '24px' },
            { borderRadius: data.borRadius + 'px'},
            { backgroundColor: data.style.cardBgColor },
        ]">
        <div>
          <div class="type-two-wrap">
            <div class="x-b">
              <div class="top3">
                <div class="faces"></div>
                <div class="userName flex1" :style="{color: data.style.textColor }">
                  <div class="name omit1">亲爱的会员</div>
                  <div class="level omit1">会员等级</div>
                </div>
              </div>
              <div class="bottom3">
                <div class="column" v-for="(item, index) in columnList" :key="index">
                  <span class="title" :style="{color: data.style.textColor }">{{item.title}}</span>
                  <span class="num fontBold" :style="{ color: data.style.realTextColor }">{{item.num}}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="data.style.isVipQrCode">
            <ZiChanCom :layout='data.style.imgLayoutType' :selectedList="data.style.selectedImgList" />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="data.style.templateNo == 5" :style="[
            { paddingLeft: data.marL + 'px' },
            { paddingRight: data.marR + 'px' },
            { paddingTop: data.marT + 'px' },
            { paddingBottom: data.marB + 'px' }
        ]">
      <div>
        <div class="x-x vipCardType5">
          <div class="y-f vipInfo" :style="[
            { borderRadius: data.borRadius + 'px'},
            { backgroundColor: data.style.cardBgColor },
        ]">
            <div class="y-f head">
              <div class="avatar"></div>
              <div class="vipName">
                <span class="name" :style="{color: data.style.textColor }">亲爱的会员</span><img
                  style="height: 16px;width: 20px;"
                  src="https://b.yzcdn.cn/public_files/a429ec4c0459fed1a41693ef8b75b750.png" alt="星鑽">
              </div>
            </div>
            <div class="feature">
              <div class="featureTop">
                <div class="featureTopItem" style="width:50%; height:100%">
                  <div class="title" :style="{color: data.style.textColor }">余额</div>
                  <div class="num" :style="{color: data.style.realTextColor }">0</div>
                  <div class="described">查看更多</div>
                </div>
                <div class="featureTopItem" style="width:50%; height:100%">
                  <div class="title" :style="{color: data.style.textColor }">积分</div>
                  <div class="num" :style="{color: data.style.realTextColor }">0</div>
                  <div class="described">查看更多</div>
                </div>
              </div>
              <div class="verticalLine" style="height: 100%"></div>
              <div class="horizontalLine"></div>
              <div class="featureBottom">
                <div class="featureBottomItem" style="width:50%; height:100%">
                  <div class="title" :style="{color: data.style.textColor }">优惠券</div>
                  <div class="num" :style="{color: data.style.realTextColor }">0</div>
                  <div class="described">查看更多</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pickUp">
            <div :style="[
            { borderRadius: data.borRadius + 'px'}
        ]" style="margin-bottom: 12px; height: calc(50% - 6px); border-radius: 8px; box-shadow: none; border: none;"
              class="imgCard">
              <div
                :style="{background: `url(${data.style.topImageUrl || 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-10-23/b5f7779d85ca45a19ce65078ee047bb8.gif'}) center center / cover no-repeat`}"
                style="height: 100%; width: 100%;"></div>
            </div>
            <div :style="[
            { borderRadius: data.borRadius + 'px'}
        ]" style="height: calc(50% - 6px); border-radius: 8px; box-shadow: none; border: none;" class="imgCard">
              <div
                :style="{background: `url(${data.style.bottomImageUrl || 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-10-23/c030d7448aed4796bdf7fd7264da60da.gif'}) center center / cover no-repeat`}"
                style="height: 100%; width: 100%;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZiChanCom from "@/views/O2OMall/config/template/decoratePage/components/conterTemplate/components/ziChanCom.vue";
export default {
  name: 'zichan',
  components: { ZiChanCom },
  props: {
    data: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      columnList: [
        {
          title: '余额',
          num: 0,
          iconName: 'money-bag'
        },
        {
          title: '积分',
          num: 0,
          iconName: 'integral'
        },
        {
          title: '优惠券',
          num: 0,
          iconName: 'coupon'
        }
      ]
    }
  },
}
</script>

<style lang='scss' scoped>
.vipCardType5 {
  .vipInfo {
    padding: 16px 16px 24px;
    margin-right: 12px;
    width: calc(50% - 6px);
    .avatar {
      margin-bottom: 8px;
      border: 2px solid #fff;
      border-radius: 50%;
      box-sizing: border-box;
      display: inline-block;
      width: 40px;
      height: 40px;
      background: url("https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/4e24133991c845368af8c104ac945003.jpg")
        0% 0% / 100% 100% no-repeat;
    }
    .vipName {
      height: 36px;
      .name {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        height: 20px;
        line-height: 20px;
        margin-right: 4px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }
    .feature {
      display: flex;
      margin-top: 30px;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      .featureTop {
        display: flex;
        height: 80px;
        width: 100%;
        .featureTopItem {
          .title {
            font-size: 12px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            width: 100%;
          }
          .num {
            font-size: 18px;
            font-weight: 600;
            height: 28px;
            line-height: 28px;
            text-align: center;
            width: 100%;
          }
          .described {
            font-size: 10px;
            height: 14px;
            line-height: 14px;
            opacity: 0.5;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
          }
        }
      }
      .verticalLine {
        background-color: rgba(50, 50, 51, 0.1);
        left: 50%;
        position: absolute;
        -webkit-transform: scalex(0.5);
        transform: scalex(0.5);
        width: 1px;
      }
      .horizontalLine {
        background-color: rgba(50, 50, 51, 0.1);
        height: 1px;
        position: absolute;
        top: 50%;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        width: 100%;
      }
      .featureBottom {
        display: flex;
        height: 80px;
        width: 100%;
        .featureBottomItem {
          height: 100%;
          padding-top: 16px;
          width: 50%;
          .title {
            font-size: 12px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            width: 100%;
          }
          .num {
            font-size: 18px;
            font-weight: 600;
            height: 28px;
            line-height: 28px;
            text-align: center;
            width: 100%;
          }
          .described {
            font-size: 10px;
            height: 14px;
            line-height: 14px;
            opacity: 0.5;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
          }
        }
      }
    }
  }
  .pickUp {
    width: calc(50% - 6px);
    .imgCard {
      background-color: #fff;
      overflow: hidden;
      width: 100%;
    }
  }
}
.warpCard {
  box-sizing: border-box;
  padding: 16px;
  min-height: 76px;
  width: 100%;
  .type-two-wrap {
    .top {
      .userinfo-mode-two-type-one {
        .left {
          .hello {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 4px;
          }
          .username {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
        }
        .right {
          margin-top: -42px;
          text-align: center;
          .avatar {
            border: 1px solid #fff;
            border-radius: 50%;
            box-sizing: border-box;
            height: 58px;
            margin-bottom: 8px;
            width: 58px;
          }
          .member-code {
            background-color: #b2906b;
            border-radius: 16px;
            color: #fff;
            display: -webkit-box;
            display: -webkit-flex;
            display: flex;
            font-size: 12px;
            height: 24px;
            line-height: 18px;
            text-align: center;
            width: 76px;
            -webkit-box-align: center;
            -webkit-align-items: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
          }
        }
      }
    }
    .top2 {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      .faces {
        background: url("https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/4e24133991c845368af8c104ac945003.jpg")
          no-repeat;
        background-size: 100% 100%;
        border: 2px solid #fff;
        border-radius: 50%;
        box-sizing: border-box;
        display: inline-block;
        margin-right: 8px;
        width: 48px;
        height: 48px;
      }
      .userName {
        box-sizing: border-box;
        height: 48px;
        padding: 2px 0;
        .head {
          display: -webkit-box;
          display: -webkit-flex;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
          align-items: center;
          .name {
            display: inline-block;
            font-size: 15px;
            font-weight: 700;
            height: 24px;
            line-height: 24px;
            margin-right: 4px;
          }
        }
        .level {
          font-size: 12px;
          line-height: 18px;
          opacity: 0.5;
        }
      }
    }
    .top3 {
      height: 48px;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      flex-grow: 1;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      .faces {
        background: url("https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/4e24133991c845368af8c104ac945003.jpg")
          no-repeat;
        background-size: 100% 100%;
        border: 2px solid #fff;
        border-radius: 50%;
        box-sizing: border-box;
        display: inline-block;
        margin-right: 8px;
        width: 44px;
        height: 44px;
      }
      .userName {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        flex-direction: column;
        .name {
          font-size: 15px;
          font-weight: 700;
          height: 24px;
          line-height: 24px;
          margin-right: 4px;
        }
        .level {
          font-size: 12px;
          line-height: 18px;
          opacity: 0.5;
        }
      }
    }
    .bottom {
      margin-top: 20px;
      .column {
        -webkit-box-flex: 1;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
        flex-grow: 1;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        position: relative;
        .num {
          font-size: 20px;
          height: 30px;
          line-height: 30px;
          min-width: 12px;
          text-align: center;
        }
        .title {
          font-size: 12px;
          height: 18px;
          line-height: 18px;
          opacity: 0.5;
          text-align: center;
          width: 96px;
        }
      }
      .column:not(:last-child)::after {
        background-color: #d8d8d8;
        content: "";
        height: 36px;
        position: absolute;
        right: 0;
        top: 7px;
        width: 0.5px;
      }
    }
    .bottom3 {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      .column {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        position: relative;
        width: 60px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        color: #333;
        -webkit-flex-direction: column;
        flex-direction: column;
        text-align: center;
        .title {
          font-size: 11px;
          font-weight: 500;
          line-height: 18px;
        }
        .num {
          line-height: 30px;
        }
      }
    }
  }
}
</style>
