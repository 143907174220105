<template>
  <div>
    <div :style="[
    {backgroundColor: data.style.bgColor},
    {paddingTop: data.style.paddingTop + 'px'},
    {paddingBottom: data.style.paddingBottom + 'px'},
    {paddingLeft: data.style.paddingAround + 'px'},
    {paddingRight: data.style.paddingAround + 'px'},
    ]">
      <div :style="[{borderRadius: data.style.borderRadius + 'px'}, {backgroundColor: data.style.cardColor}]">
        <div v-if="data.style.display && data.style.styleType == 1" style="height:155px " class="x-fc">
          <div class="item1" v-if="data.style.isWeChat">
            <div class="img">
              <img :src="data.style.weChatUrl || defaultUrl" alt="社群码"
                style="height: 100%; object-fit: cover;width: 100%;" />
            </div>
            <div class="desc" :style="{color: data.style.describedColor}" v-if="data.style.described">
              {{ data.style.described }}
            </div>
          </div>
          <div class="line" v-if="data.style.isWeChat && data.style.isOfficialAccounts"></div>
          <div class="item1" v-if="data.style.isOfficialAccounts">
            <div class="img">
              <img :src="data.style.officialAccountsUrl || defaultUrl" alt="社群码"
                style="height: 100%; object-fit: cover;width: 100%;" />
            </div>
            <div class="desc" :style="{color: data.style.describedColor}" v-if="data.style.describedOfficialAccounts">
              {{ data.style.describedOfficialAccounts }}
            </div>
          </div>
        </div>
        <div v-else-if='data.style.display && data.style.styleType == 2' class="styleTwo x-bc">
          <div>
            <div :style="{color: data.style.titleColor}" class="title" v-if="data.style.title">{{data.style.title}}
            </div>
            <div :style='{color: data.style.describedColor}' class="desc2" v-if="data.style.described">
              {{data.style.described}}</div>
          </div>
          <div class="img">
            <img :src="data.style.weChatUrl || defaultUrl" alt="社群码"
              style="height: 100%; object-fit: cover;width: 100%;" />
          </div>
        </div>
        <div v-else-if="!data.style.display" class="weChat">
          <div class="left flex1">
            <span class="logo"
              :style="{backgroundImage: `url(${data.style.entranceType == 2 ? data.style.entranceUrl : 'https://img01.yzcdn.cn/upload_files/2023/07/14/FhRlvzI_2wr2xP5DV7QQ79iemeM1.png!small.webp'})`, backgroundColor:`${(data.style.entranceType == 2 && !data.style.entranceUrl) ? '#dadada' : 'transparent'}`}"></span>
            <div class="logoTitle">
              <p class="title" :style="{color: data.style.titleColor}" v-if="data.style.title">{{data.style.title}}</p>
              <p class="desc" :style="{color: data.style.describedColor}" v-if="data.style.described">
                {{data.style.described}}</p>
            </div>
          </div>
          <div class="right">
            <span class="btn" :style="{backgroundColor: data.style.btnBgColor}">{{`${data.style.weChatOrOfficialAccountsType == 1 ? data.style.addBtnName : '关注'}`}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'zhangfen',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      defaultUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png'
    }
  },
}
</script>

<style lang='scss' scoped>
.item1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 130px;
  .desc {
    height: 18px;
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
.line {
  background: #eee;
  height: 75px;
  position: relative;
  top: -12px;
  width: 1px;
}
.img {
  padding: 5px;
  background: #000;
  background-clip: content-box;
  background-color: #d9d9d9;
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  box-sizing: border-box;
  height: 79px;
  overflow: hidden;
  width: 79px;
}
.styleTwo {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  height: 108px;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 0 16px;
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
  .desc2 {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
.weChat {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  font-size: 12px;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 18px 16px;
  .left {
    overflow: hidden;
    display: flex;
    .logo {
      background-position: 50%;
      background-size: cover;
      border-radius: 8px;
      display: block;
      -webkit-flex-shrink: 0;
      flex-shrink: 0;
      height: 48px;
      margin-right: 10px;
      width: 48px;
      background-color: transparent;
    }
    .logoTitle {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex: 1;
      flex: 1;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      padding-top: 3px;
      width: calc(100% - 58px);
      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #323233;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
      }
      .desc {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #969799;
        line-height: 18px;
      }
    }
  }
  .right {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    width: 90px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    .btn {
      background-color: #a8865d;
      border-radius: 16px;
      color: #fff;
      display: block;
      height: 32px;
      line-height: 32px;
      text-align: center;
      padding: 0 20px;
    }
  }
}
p {
  padding: 0;
  margin: 0;
}
</style>
