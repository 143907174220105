var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.data.style.templateNo == 1
      ? _c(
          "div",
          {
            staticClass: "item",
            style: [
              { paddingLeft: _vm.data.style.pagePadding + "px" },
              { paddingRight: _vm.data.style.pagePadding + "px" },
              {
                backgroundColor:
                  _vm.data.style.cardStyle == 2 ? _vm.data.style.bgColor : "",
              },
              {
                backgroundColor:
                  _vm.data.style.cardStyle == 3 ? _vm.data.style.bgColor : "",
              },
              {
                margin:
                  _vm.data.marT +
                  "px " +
                  _vm.data.marR +
                  "px " +
                  _vm.data.marB +
                  "px " +
                  _vm.data.marL +
                  "px",
              },
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "x-bc",
                staticStyle: { padding: "10px" },
                style: [
                  {
                    backgroundColor:
                      _vm.data.style.cardStyle == 4
                        ? "rgba(255,255,255,0)"
                        : _vm.data.style.cardBgColor,
                  },
                  { borderRadius: _vm.data.borRadius + "px" },
                  {
                    boxShadow:
                      _vm.data.style.cardStyle == 2
                        ? "-3px 3px 3px rgba(0,0,0,0.2), 3px 0px 3px rgba(0,0,0,0.2)"
                        : "",
                  },
                  {
                    border:
                      _vm.data.style.cardStyle == 3 ? "1px solid #e9e9e9" : "",
                  },
                  {
                    backgroundImage:
                      _vm.data.style.bgStyle === "image"
                        ? "url(" + _vm.data.style.bgImage + ")"
                        : "",
                  },
                  { backgroundSize: "cover" },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "x-f" },
                  [
                    _vm.data.style.isAvatar
                      ? _c("el-avatar", {
                          attrs: {
                            src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/4e24133991c845368af8c104ac945003.jpg",
                            size: 40,
                          },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "y-start marL10" }, [
                      _c(
                        "span",
                        {
                          staticClass: "fontBold",
                          staticStyle: {
                            "margin-bottom": "5px",
                            "font-size": "14px",
                          },
                          style: { color: _vm.data.style.textColor },
                        },
                        [_vm._v("Hi，亲爱的会员")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "x-f",
                          staticStyle: {
                            color: "#bababa",
                            "font-size": "11px",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.data.style.propertyMsg) + " "
                          ),
                          _c("i", {
                            staticClass: "el-icon-arrow-right",
                            staticStyle: { "margin-left": "2px" },
                          }),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "x-f" }, [
                  _c(
                    "div",
                    {
                      staticClass: "fontS12 y-c",
                      staticStyle: { "margin-right": "10px" },
                      style: { color: _vm.data.style.textColor },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "margin-bottom": "5px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.data.style.descriptionText))]
                      ),
                      _c("span", { staticStyle: { color: "#bababa" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.data.style.descriptionText == "余额"
                              ? "￥0"
                              : "0张"
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm.data.style.isVipQrCode
                    ? _c(
                        "div",
                        { staticClass: "y-f fontS12" },
                        [
                          _c("iconpark-icon", {
                            attrs: {
                              name: "two-dimensional-code-two",
                              size: "20px",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "9px",
                                color: "#bababa",
                              },
                            },
                            [_vm._v("会员码")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]
        )
      : _vm.data.style.templateNo == 2
      ? _c(
          "div",
          {
            style: [
              { paddingLeft: _vm.data.marL + "px" },
              { paddingRight: _vm.data.marR + "px" },
              { paddingTop: _vm.data.marT + "px" },
              { paddingBottom: _vm.data.marB + "px" },
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "warpCard",
                style: [
                  { paddingTop: "24px" },
                  { borderRadius: _vm.data.borRadius + "px" },
                  { backgroundColor: _vm.data.style.cardBgColor },
                ],
              },
              [
                _c("div", [
                  _c("div", { staticClass: "type-two-wrap" }, [
                    _c("div", { staticClass: "top" }, [
                      _c(
                        "div",
                        { staticClass: "x-b userinfo-mode-two-type-one" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "left",
                              style: { color: _vm.data.style.textColor },
                            },
                            [
                              _c("div", { staticClass: "hello" }, [
                                _vm._v("Hello"),
                              ]),
                              _c("div", { staticClass: "username" }, [
                                _vm._v("亲爱的会员"),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "right" }, [
                            _c("img", {
                              staticClass: "avatar",
                              attrs: {
                                src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/4e24133991c845368af8c104ac945003.jpg",
                                alt: "头像图片",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "member-code" },
                              [
                                _c("iconpark-icon", {
                                  staticStyle: {
                                    height: "14px",
                                    "margin-right": "2px",
                                    width: "14px",
                                  },
                                  attrs: {
                                    name: "two-dimensional-code-two",
                                    size: "14px",
                                  },
                                }),
                                _c("span", [_vm._v("会员码")]),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "bottom x-fsa" },
                      _vm._l(_vm.columnList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "column" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "num fontBold",
                                style: { color: _vm.data.style.realTextColor },
                              },
                              [_vm._v(_vm._s(item.num))]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "title",
                                style: { color: _vm.data.style.textColor },
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm.data.style.isVipQrCode
                    ? _c(
                        "div",
                        [
                          _c("ZiChanCom", {
                            attrs: {
                              layout: _vm.data.style.imgLayoutType,
                              selectedList: _vm.data.style.selectedImgList,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]
        )
      : _vm.data.style.templateNo == 3
      ? _c(
          "div",
          {
            style: [
              { paddingLeft: _vm.data.marL + "px" },
              { paddingRight: _vm.data.marR + "px" },
              { paddingTop: _vm.data.marT + "px" },
              { paddingBottom: _vm.data.marB + "px" },
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "warpCard",
                style: [
                  { paddingTop: "24px" },
                  { borderRadius: _vm.data.borRadius + "px" },
                  { backgroundColor: _vm.data.style.cardBgColor },
                ],
              },
              [
                _c("div", [
                  _c("div", { staticClass: "type-two-wrap" }, [
                    _c("div", { staticClass: "x-b" }, [
                      _c("div", { staticClass: "top2" }, [
                        _c("div", { staticClass: "faces" }),
                        _c(
                          "div",
                          {
                            staticClass: "userName",
                            style: { color: _vm.data.style.textColor },
                          },
                          [
                            _vm._m(0),
                            _c("div", { staticClass: "level" }, [
                              _vm._v("会员等级"),
                            ]),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "y-f" },
                        [
                          _c("iconpark-icon", {
                            attrs: {
                              name: "two-dimensional-code-two",
                              size: "20px",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                color: "#bababa",
                              },
                            },
                            [_vm._v("会员码")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "bottom x-fsa" },
                      _vm._l(_vm.columnList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "column" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "num fontBold",
                                style: { color: _vm.data.style.realTextColor },
                              },
                              [_vm._v(_vm._s(item.num))]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "title",
                                style: { color: _vm.data.style.textColor },
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm.data.style.isVipQrCode
                    ? _c(
                        "div",
                        [
                          _c("ZiChanCom", {
                            attrs: {
                              layout: _vm.data.style.imgLayoutType,
                              selectedList: _vm.data.style.selectedImgList,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]
        )
      : _vm.data.style.templateNo == 4
      ? _c(
          "div",
          {
            style: [
              { paddingLeft: _vm.data.marL + "px" },
              { paddingRight: _vm.data.marR + "px" },
              { paddingTop: _vm.data.marT + "px" },
              { paddingBottom: _vm.data.marB + "px" },
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "warpCard",
                style: [
                  { paddingTop: "24px" },
                  { borderRadius: _vm.data.borRadius + "px" },
                  { backgroundColor: _vm.data.style.cardBgColor },
                ],
              },
              [
                _c("div", [
                  _c("div", { staticClass: "type-two-wrap" }, [
                    _c("div", { staticClass: "x-b" }, [
                      _c("div", { staticClass: "top3" }, [
                        _c("div", { staticClass: "faces" }),
                        _c(
                          "div",
                          {
                            staticClass: "userName flex1",
                            style: { color: _vm.data.style.textColor },
                          },
                          [
                            _c("div", { staticClass: "name omit1" }, [
                              _vm._v("亲爱的会员"),
                            ]),
                            _c("div", { staticClass: "level omit1" }, [
                              _vm._v("会员等级"),
                            ]),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bottom3" },
                        _vm._l(_vm.columnList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "column" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "title",
                                  style: { color: _vm.data.style.textColor },
                                },
                                [_vm._v(_vm._s(item.title))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "num fontBold",
                                  style: {
                                    color: _vm.data.style.realTextColor,
                                  },
                                },
                                [_vm._v(_vm._s(item.num))]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _vm.data.style.isVipQrCode
                    ? _c(
                        "div",
                        [
                          _c("ZiChanCom", {
                            attrs: {
                              layout: _vm.data.style.imgLayoutType,
                              selectedList: _vm.data.style.selectedImgList,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]
        )
      : _vm.data.style.templateNo == 5
      ? _c(
          "div",
          {
            style: [
              { paddingLeft: _vm.data.marL + "px" },
              { paddingRight: _vm.data.marR + "px" },
              { paddingTop: _vm.data.marT + "px" },
              { paddingBottom: _vm.data.marB + "px" },
            ],
          },
          [
            _c("div", [
              _c("div", { staticClass: "x-x vipCardType5" }, [
                _c(
                  "div",
                  {
                    staticClass: "y-f vipInfo",
                    style: [
                      { borderRadius: _vm.data.borRadius + "px" },
                      { backgroundColor: _vm.data.style.cardBgColor },
                    ],
                  },
                  [
                    _c("div", { staticClass: "y-f head" }, [
                      _c("div", { staticClass: "avatar" }),
                      _c("div", { staticClass: "vipName" }, [
                        _c(
                          "span",
                          {
                            staticClass: "name",
                            style: { color: _vm.data.style.textColor },
                          },
                          [_vm._v("亲爱的会员")]
                        ),
                        _c("img", {
                          staticStyle: { height: "16px", width: "20px" },
                          attrs: {
                            src: "https://b.yzcdn.cn/public_files/a429ec4c0459fed1a41693ef8b75b750.png",
                            alt: "星鑽",
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "feature" }, [
                      _c("div", { staticClass: "featureTop" }, [
                        _c(
                          "div",
                          {
                            staticClass: "featureTopItem",
                            staticStyle: { width: "50%", height: "100%" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                style: { color: _vm.data.style.textColor },
                              },
                              [_vm._v("余额")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "num",
                                style: { color: _vm.data.style.realTextColor },
                              },
                              [_vm._v("0")]
                            ),
                            _c("div", { staticClass: "described" }, [
                              _vm._v("查看更多"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "featureTopItem",
                            staticStyle: { width: "50%", height: "100%" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                style: { color: _vm.data.style.textColor },
                              },
                              [_vm._v("积分")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "num",
                                style: { color: _vm.data.style.realTextColor },
                              },
                              [_vm._v("0")]
                            ),
                            _c("div", { staticClass: "described" }, [
                              _vm._v("查看更多"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", {
                        staticClass: "verticalLine",
                        staticStyle: { height: "100%" },
                      }),
                      _c("div", { staticClass: "horizontalLine" }),
                      _c("div", { staticClass: "featureBottom" }, [
                        _c(
                          "div",
                          {
                            staticClass: "featureBottomItem",
                            staticStyle: { width: "50%", height: "100%" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                style: { color: _vm.data.style.textColor },
                              },
                              [_vm._v("优惠券")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "num",
                                style: { color: _vm.data.style.realTextColor },
                              },
                              [_vm._v("0")]
                            ),
                            _c("div", { staticClass: "described" }, [
                              _vm._v("查看更多"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "pickUp" }, [
                  _c(
                    "div",
                    {
                      staticClass: "imgCard",
                      staticStyle: {
                        "margin-bottom": "12px",
                        height: "calc(50% - 6px)",
                        "border-radius": "8px",
                        "box-shadow": "none",
                        border: "none",
                      },
                      style: [{ borderRadius: _vm.data.borRadius + "px" }],
                    },
                    [
                      _c("div", {
                        staticStyle: { height: "100%", width: "100%" },
                        style: {
                          background:
                            "url(" +
                            (_vm.data.style.topImageUrl ||
                              "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-10-23/b5f7779d85ca45a19ce65078ee047bb8.gif") +
                            ") center center / cover no-repeat",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "imgCard",
                      staticStyle: {
                        height: "calc(50% - 6px)",
                        "border-radius": "8px",
                        "box-shadow": "none",
                        border: "none",
                      },
                      style: [{ borderRadius: _vm.data.borRadius + "px" }],
                    },
                    [
                      _c("div", {
                        staticStyle: { height: "100%", width: "100%" },
                        style: {
                          background:
                            "url(" +
                            (_vm.data.style.bottomImageUrl ||
                              "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-10-23/c030d7448aed4796bdf7fd7264da60da.gif") +
                            ") center center / cover no-repeat",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head" }, [
      _c("span", { staticClass: "name" }, [_vm._v("亲爱的会员")]),
      _c("img", {
        staticStyle: { height: "16px", width: "20px" },
        attrs: {
          src: "https://b.yzcdn.cn/public_files/a429ec4c0459fed1a41693ef8b75b750.png",
          alt: "星鑽",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }