var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clearfix" }, [
    [1, 2, 3].includes(_vm.layout)
      ? _c(
          "div",
          { staticClass: "cardItem" },
          _vm._l(_vm.selectedList, function (item, index) {
            return _c("div", {
              key: index,
              staticClass: "cube-selected",
              class: _vm.styleClass,
              style: { backgroundImage: "url(" + item.imageUrl + ")" },
            })
          }),
          0
        )
      : [4].includes(_vm.layout)
      ? _c("div", { staticClass: "entries2" }, [
          _c("div", { staticClass: "part-one" }, [
            _c("div", {
              staticClass: "cube-selected entry",
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.selectedList.length > 0 && _vm.selectedList[0].imageUrl
                    ? _vm.selectedList[0].imageUrl
                    : "") +
                  ")",
              },
            }),
          ]),
          _c("div", { staticClass: "part-two" }, [
            _c("div", {
              staticClass: "cube-selected entry",
              staticStyle: { "margin-bottom": "11px" },
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.selectedList.length > 1 && _vm.selectedList[1].imageUrl
                    ? _vm.selectedList[1].imageUrl
                    : "") +
                  ")",
              },
            }),
            _c("div", {
              staticClass: "cube-selected entry",
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.selectedList.length > 2 && _vm.selectedList[2].imageUrl
                    ? _vm.selectedList[2].imageUrl
                    : "") +
                  ")",
              },
            }),
          ]),
        ])
      : [5].includes(_vm.layout)
      ? _c("div", { staticClass: "entries3" }, [
          _c("div", { staticClass: "part-one" }, [
            _c("div", {
              staticClass: "cube-selected entry",
              staticStyle: { "margin-right": "11px" },
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.selectedList.length > 0 && _vm.selectedList[0].imageUrl
                    ? _vm.selectedList[0].imageUrl
                    : "") +
                  ")",
              },
            }),
            _c("div", {
              staticClass: "cube-selected entry",
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.selectedList.length > 1 && _vm.selectedList[1].imageUrl
                    ? _vm.selectedList[1].imageUrl
                    : "") +
                  ")",
              },
            }),
          ]),
          _c("div", { staticClass: "part-two" }, [
            _c("div", {
              staticClass: "cube-selected entry",
              staticStyle: { "margin-right": "11px" },
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.selectedList.length > 2 && _vm.selectedList[2].imageUrl
                    ? _vm.selectedList[2].imageUrl
                    : "") +
                  ")",
              },
            }),
            _c("div", {
              staticClass: "cube-selected entry",
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.selectedList.length > 3 && _vm.selectedList[3].imageUrl
                    ? _vm.selectedList[3].imageUrl
                    : "") +
                  ")",
              },
            }),
          ]),
        ])
      : [6].includes(_vm.layout)
      ? _c("div", { staticClass: "entries4" }, [
          _c("div", { staticClass: "part-one" }, [
            _c("div", {
              staticClass: "cube-selected entry",
              staticStyle: { "margin-right": "11px" },
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.selectedList.length > 0 && _vm.selectedList[0].imageUrl
                    ? _vm.selectedList[0].imageUrl
                    : "") +
                  ")",
              },
            }),
            _c("div", {
              staticClass: "cube-selected entry",
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.selectedList.length > 1 && _vm.selectedList[1].imageUrl
                    ? _vm.selectedList[1].imageUrl
                    : "") +
                  ")",
              },
            }),
          ]),
          _c("div", { staticClass: "part-two" }, [
            _c("div", {
              staticClass: "cube-selected entry",
              staticStyle: { "margin-right": "11px" },
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.selectedList.length > 2 && _vm.selectedList[2].imageUrl
                    ? _vm.selectedList[2].imageUrl
                    : "") +
                  ")",
              },
            }),
            _c("div", {
              staticClass: "cube-selected entry",
              staticStyle: { "margin-right": "11px" },
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.selectedList.length > 3 && _vm.selectedList[3].imageUrl
                    ? _vm.selectedList[3].imageUrl
                    : "") +
                  ")",
              },
            }),
            _c("div", {
              staticClass: "cube-selected entry",
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.selectedList.length > 4 && _vm.selectedList[4].imageUrl
                    ? _vm.selectedList[4].imageUrl
                    : "") +
                  ")",
              },
            }),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }