<template>
  <div class="clearfix">
    <!-- 这里可以放置您的内容，例如多个子组件或元素 -->
    <div class="cardItem" v-if="[1, 2, 3].includes(layout)">
      <div v-for="(item, index) in selectedList" :key="index" class="cube-selected" :class="styleClass"
        :style="{backgroundImage:`url(${item.imageUrl})`}">
      </div>
    </div>
    <div class="entries2" v-else-if="[4].includes(layout)">
      <div class="part-one">
        <div class="cube-selected entry"
          :style="{backgroundImage:`url(${(selectedList.length > 0 && selectedList[0].imageUrl) ? selectedList[0].imageUrl : ''})`}"></div>
      </div>
      <div class="part-two">
        <div class="cube-selected entry"
          :style="{backgroundImage:`url(${(selectedList.length > 1 && selectedList[1].imageUrl) ? selectedList[1].imageUrl : ''})`}"
          style="margin-bottom:11px"></div>
        <div class="cube-selected entry"
          :style="{backgroundImage:`url(${(selectedList.length > 2 && selectedList[2].imageUrl) ? selectedList[2].imageUrl : ''})`}"></div>
      </div>
    </div>
    <div class="entries3" v-else-if="[5].includes(layout)">
      <div class="part-one">
        <div class="cube-selected entry"
          :style="{backgroundImage:`url(${(selectedList.length > 0 && selectedList[0].imageUrl) ? selectedList[0].imageUrl : ''})`}"
          style="margin-right:11px"></div>
        <div class="cube-selected entry"
          :style="{backgroundImage:`url(${(selectedList.length > 1 && selectedList[1].imageUrl) ? selectedList[1].imageUrl : ''})`}"></div>
      </div>
      <div class="part-two">
        <div class="cube-selected entry"
          :style="{backgroundImage:`url(${(selectedList.length > 2 && selectedList[2].imageUrl) ? selectedList[2].imageUrl : ''})`}"
          style="margin-right:11px"></div>
        <div class="cube-selected entry"
          :style="{backgroundImage:`url(${(selectedList.length > 3 && selectedList[3].imageUrl) ? selectedList[3].imageUrl : ''})`}"></div>
      </div>
    </div>
    <div class="entries4" v-else-if="[6].includes(layout)">
      <div class="part-one">
        <div class="cube-selected entry"
          :style="{backgroundImage:`url(${(selectedList.length > 0 && selectedList[0].imageUrl) ? selectedList[0].imageUrl : ''})`}"
          style="margin-right:11px"></div>
        <div class="cube-selected entry"
          :style="{backgroundImage:`url(${(selectedList.length > 1 && selectedList[1].imageUrl) ? selectedList[1].imageUrl : ''})`}"></div>
      </div>
      <div class="part-two">
        <div class="cube-selected entry"
          :style="{backgroundImage:`url(${(selectedList.length > 2 && selectedList[2].imageUrl) ? selectedList[2].imageUrl : ''})`}"
          style="margin-right:11px"></div>
        <div class="cube-selected entry"
          :style="{backgroundImage:`url(${(selectedList.length > 3 && selectedList[3].imageUrl) ? selectedList[3].imageUrl : ''})`}"
          style="margin-right:11px"></div>
        <div class="cube-selected entry"
          :style="{backgroundImage:`url(${(selectedList.length > 4 && selectedList[4].imageUrl) ? selectedList[4].imageUrl : ''})`}"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ziChanCom',
  data() {
    return {
    }
  },
  props: {
    layout: {
      type: [Number, String],
      required: true,
    },
    selectedList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    styleClass() {
      switch (this.layout) {
        case 1:
          return 'one-per-row';
        case 2:
          return 'two-per-row';
        case 3:
          return 'three-per-row';
        case 4:
          return 'one-left-two-right';
        case 5:
          return 'two-left-two-right';
        case 6:
          return 'two-row-five';
        default:
          return '';
      }
    },
  },
  methods: {
  },
};
</script>

<style lang='scss' scoped>
.clearfix {
  padding-top: 16px;
  .cardItem {
    display: flex;
    justify-content: space-evenly;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
    display: -webkit-box;
    display: -webkit-flex;
  }
  .cube-selected {
    border-radius: 8px;
    background-color: #f2f2f2;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    line-height: 0;
    position: relative;
    width: 100%;
  }
}
//一行一个
.one-per-row {
  padding-bottom: 35.37%;
}

//一行两个
.two-per-row {
  padding-bottom: calc(36.65% - 4.0315px);
}
.two-per-row:nth-child(2) {
  margin-left: 11px;
}

//一行三个
.three-per-row {
  padding-bottom: calc(37.33333% - 8.21333px);
}
.three-per-row:nth-child(2),
.three-per-row:nth-child(3) {
  margin-left: 11px;
}

.entries2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  .part-one {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    margin-right: 11px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    .entry {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
    }
  }
  .part-two {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    .entry {
      padding-bottom: 73.3%;
    }
  }
}
.entries3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
  .part-one {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 11px;
    width: 100%;
    .entry {
      padding-bottom: calc(36.65% - 4.0315px);
    }
  }
  .part-two {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    .entry {
      padding-bottom: calc(36.65% - 4.0315px);
    }
  }
}
.entries4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
  .part-one {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 11px;
    width: 100%;
    .entry {
      padding-bottom: calc(36.65% - 4.0315px);
    }
  }
  .part-two {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    .entry {
     padding-bottom: calc(37.33333% - 8.21333px);
    }
  }
}
</style>